import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <section className="footer">
            <p>2022 &copy; Patrick Dayton </p>
        </section>
    )
};
export default Footer;

