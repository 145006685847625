import React from 'react';
import PortfolioPage from './pages/PortfolioPage';

const Portfolio = ( ) => {
    return (
        <>
            <PortfolioPage />
        </>
    )
};

export default Portfolio;